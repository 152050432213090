import React, { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Heading, Image, Text } from '@legalshield/adonis-ux-framework';

import Config from '../../../config';
import URLs from '../../../utils/url';
import { PlanControl } from '../../Controls/PlanControl';

export interface AssociateCardProps {
  count: number;
}

const AssociateCard: FC<AssociateCardProps> = ({ count }) => {
  const getAppUrl = () => {
    return URLs.getLSAppUrl(Config.currentEnv.apps.associate);
  };
  const url = getAppUrl();

  const associatesLabel = count >= 1 ? string_table.PLANS_ASSOCIATE_AGREEMENTS : string_table.PLANS_ASSOCIATE_AGREEMENT;

  if (count === 0) return <Fragment />;

  return (
    <Link to={url} className="lsux-card accounts-card accounts-card--clickable">
      <Container flexbox flexDirection="column" classNames={["p-5"]}>
        <div>
          <Image
            src={'https://design.api.' + pplsi.realmDomain + '/assets/logos/pplsipeople-square-logo.svg'}
            alt={'PPLSI Logo'}
            style={{
              width: '48px',
              height: '48px',
            }}
          />
        </div>
        <Heading as="T20" text={string_table.PLANS_ASSOCIATE} classNames={['mt-4']} />
        <PlanControl planTitle={string_table.PLANS_ASSOCIATE_AGREEMENTS} classNames={['p-4']} showExternalLink={false}>
          <Text
            as="p"
            textSize="small"
            classNames={['pt-3', 'accounts-card__text']}
          >{`${count} ${associatesLabel}`}</Text>
        </PlanControl>
      </Container>
    </Link>
  );
};

export default AssociateCard;

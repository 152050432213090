import React, { FC } from 'react';
import { Link, Container, Icon } from '@legalshield/adonis-ux-framework';

import Config from '../../config';
import URLs from '../../utils/url';

export const LinkToAccess: FC = () => (
  <Container flexbox background='light-gray' alignItems="center" justifyContent="center" classNames={['mt-5']}>
    <Link
      classNames={['text-nowrap']}
      icon="alert_information"
      iconPosition="left"
      href={URLs.getLSAppUrl(Config.currentEnv.apps.access)}
      text={string_table.MEMBERSHIPS_ACCESS_LINK}
      textSize="medium"
      textWeight="semibold"
    />
    <Icon name="arrow_chevron_right" size="small" />
  </Container>
);
